.enquiry-form {
    position: relative;
	width: 52rem;
	max-width: 100%;
	margin: 0 auto;
	padding: 3rem 5.7rem;
	border-radius: 0.8rem;
    overflow: hidden;
	background-color: $white;
	box-shadow: 0 4px 21px 0 rgba(72,97,117,0.2);

  	@media(max-width: $screen-sm) {
		padding: 3rem 2rem;
	}
	
	label {
		display: block;
		margin: 0;
		line-height: 2.6rem;
		font-size: 1.15rem;
		letter-spacing: 0.1rem;
		color: $grey-black;
		
		&.error { display: none; }
	}

	textarea {
		height: 17.5rem;

		@media(max-width: $screen-sm) {
			height: 7.5rem;
		}
	}
	
	.siteBtn {
		width: auto;
	}

	input:not([type=checkbox]):not(.siteBtn) {
		padding-right: 4.5rem;
	}

	input:not([type=checkbox]):not(.siteBtn), textarea {
		background: transparent;
		border: none;
		color: rgb(72,97,117);
		font-size: 1.8rem;
		border-bottom: 0.2rem solid $grey-black;
		resize: none;

		@media(max-width: $screen-sm) {
			font-size: 1.6rem;
		}

		&:not(.wpcf7-textarea).valid {
			border-color: rgba(72,97,117,0.5);
			background: $white url('images/valid.svg') no-repeat center right 1rem/3rem!important;
		}
		&:not(.wpcf7-textarea).error {
			background: $white url('images/invalid.svg') no-repeat center right 1.8rem/1.4rem!important;
		}

		&:focus {
			color: $off-black;
			border-color: transparent;
		}
	}

	input::placeholder, textarea::placeholder {
		color: rgba(72,97,117,0.5);
	}

	input[type=submit]:not(.siteBtn) {
		max-width:24rem;
		letter-spacing: 1.14px;
	}
	
	.checkboxed {
		// Hide the assistive text
		.wpcf7-list-item-label {
			display: none;
		}
	
		strong {
			display: block;
			position: relative;
			padding-left: 3.5rem;
			font-weight: normal;
			
			em {
				display: inline-block;
				font-size: 1.4rem;
				text-align: left; 
			}
		}
		.wpcf7-form-control-wrap { position: unset; }
		.wpcf7-list-item { margin: 0; }
		input {
			position: absolute;
			width: 2.4rem;
			height: 2.4rem;
			left: 0;
			top: 0.5rem;
			padding: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			border: 0.5px solid #979797;
			background-color: #F9F9F9;
			cursor: pointer;

			&:checked {
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 0.6rem;
					height: 1.3rem;
					left: 0.9rem;
					bottom: 0.7rem;
					border: solid #979797;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}
		}
	}

	.form-submit { position:relative; text-align: center; }

	/*.ajax-loader*/.wpcf7-spinner { position: absolute; top: 1rem; }

	.group-form { margin-bottom: 1.6rem; }
	
	.wpcf7-not-valid-tip {
		color: red;
	}

	// Popup error form with a "close" button
	.response-form {
		display:none;

		& .response-inner .response-icon {
			background: url('images/fail.svg') no-repeat center/contain;
		}

		&.success{
			.response-inner .response-icon {
				background: url('images/success.svg') no-repeat center/contain;
			}
		}

		.response-inner {
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 0;
			padding:14.5rem 4.5rem 4.5rem;
			bottom: 0;
			background: white;
			display: flex;
			justify-content: center;

			.response-icon{
				width:9.2rem;
				display:inline-block;
				margin-bottom:5rem;
				height:9.2rem;
			}

			h3{
				height:4.2rem;
				margin-bottom:2rem;
				color:$pink;
			}

			// The Contact Form 7 success/error message
			.wpcf7-response-output {
				border: none;
				font-size: 1.4rem;
				line-height: 3.2rem;
				margin: 0 0 1rem;
				padding: 1rem 0;
				color:$grey;
				background: $white;

				@media(max-width: $screen-md) {
					line-height: 2.8rem;
					font-size: 1.8rem;
				}
				@media(max-width: $screen-sm) {
					font-size: 1.6rem;
					line-height: 2.5rem;
				}
			}
		}
	}
}
