.sm-testimonial {
	@include sm-block-globals;

	padding: 4.7rem 0;
	
	.sm-common-title__desc {
		max-width: 70.5rem;	
		margin: 2rem auto 3rem;
		font-size: 2.5rem;
		line-height: 3rem;

		@media(max-width: $screen-sm) {
			font-size: 2rem;
			line-height: 3rem;		
		}
	}
	
	&__source {
		text-align: center;
	}
	
	&__person {
		margin: 0;
		line-height: 1.6rem;
		font-size: 1.6rem;
		letter-spacing: -.13px;
	}
	
	&__role {
		font-size: 1.4rem;
		font-weight: 300;	
	}
}