.sm-icon-slider {
	@include sm-block-globals;

    padding: 4.7rem 0;

	.slick-slider {
		img {
			margin: auto;
			width: auto;
		}
	}

	@media (max-width: $screen-md) {
		.slick-slider {
			margin-top: 2rem;
		}
	}
}