
// Outer container for basic page content
.default-page-container {
	max-width: 107rem;
	min-height: 25rem;
	margin:0 auto;
}


// Simple banner image using the page image and page title
.default-banner {
	height: 30rem;
    display: flex;
    flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-color: $off-black;
	background-position: center;
	@media(max-width: $screen-sm) {
		height: 17rem;
	}
	
	h1 {
		margin: 0;
		font-size: 4rem;
		font-weight: 400;
		line-height: 5rem;
		letter-spacing: -.32px;
		color: $white;
	}
}

.default-content {
	padding: 6rem 0;
	max-width: 83rem;
	margin: 0 auto;
	display: block;
	
	@media(max-width: $screen-sm) {
		padding-bottom: 0;
	}

	p {
		margin-bottom: 2rem;
		font-size: 1.8rem;
		line-height: 2.4rem;
		letter-spacing: -.11px;

		@media(max-width: $screen-sm) {
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
	}

	a {
		color: $pink;
	}
	
	h2 {
		margin-bottom: 2rem;
		font-size: 3.5rem;
		font-weight: 400;
		line-height: 4.2rem;

  		@media(max-width: $screen-md) {
			font-size: 3rem;
			line-height: 3.6rem;
		}
		@media(max-width: $screen-sm) {
			font-size: 2.6rem;
			line-height: 3.2rem;
		}
	}
	
	h3 {
	    margin-bottom: 1rem;
	    font-size: 2.6rem;
		font-weight: 400;
		
		@media(max-width: $screen-sm) {
			font-size: 2rem;
			line-height: 2.6rem;
		}
	}
	
	h4 {
		margin-bottom: 1.5rem;
		font-size: 2.2rem;
		font-weight: 400;
		line-height: 3.2rem;
	}

	ul {
		margin-bottom: 1.5rem;
		list-style: none;
	}

	li {
		position: relative;
		padding-left: 1.5rem;

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 0.7rem;
			height: 0.7rem;
			left: 0;
			top: 0.9rem;
			border-radius: 50%;
			border: 0.1rem solid $off-black;
		}
	}
}