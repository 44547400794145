.sm-text-image-banner {
	@include sm-block-globals;

	display: flex;
	min-height: 45rem;
    padding: 4.7rem 0;
    align-items: center;
	background-size: cover;
	
	&__outer {
	    z-index: 1;
		position: relative;
	
		@media(max-width: $screen-sm) {
		    flex-direction: column-reverse;
		}
	}

	&__left, &__right {
		flex: 0 0 50%;
		max-width: 50%;
		margin: auto 0;

		@media(max-width: $screen-sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	
	&__small-heading {
		font-family: $font-solomon;
		font-size: 1.3rem;
		font-weight: 400;
		line-height: 2.6rem;	
		letter-spacing: 1.78px;
	}

	&__big-heading {
		margin: 3rem 0;
		line-height: 6.2rem;
		font-size: 5.4rem;
		font-weight: 400;
		font-family: $font-solomonLight;
		letter-spacing: 0;	

		@media(max-width: $screen-md) {
			margin: 2rem 0;
			font-size: 4rem;			
			line-height: 5rem;
		}
		@media(max-width: $screen-sm) {
			font-size: 3.5rem;
			line-height: 4.5rem;		
		}
	}
	
	&__desc {
		max-width: 55rem;	
	    margin-bottom: 1rem;
		font-size: 2.4rem;
		line-height: 3.2rem;		
		letter-spacing: -.11px;

		@media(max-width: $screen-md) {
			font-size: 1.8rem;
			line-height: 2.4rem;		
		}
		@media(max-width: $screen-sm) {
			font-size: 1.6rem;
			line-height: 2.2rem;
		}

		p {
			color: inherit;
		}
	}
	
	.btn-group {
		margin-top: 4rem;	
	}
	
	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;		
		
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	
	&__top {
		position: relative;
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__bottom {
		flex-shrink: 0;
	}
	
	&__small {
		position: absolute;
/*		right: 2.4rem;
		top: 2.4rem;	*/
		top: 0;
		right: 0;
		z-index: 11;
	}
	
	&__image {
		display: block;
		height: auto;
		padding: 0 1rem;
		position: relative;
		z-index: 10;
		/*pointer-events: auto;*/			/* Allow click through */
	}
	
	&__blurb {
		margin-top: 1rem;
		font-size: 2rem;
		line-height: 3rem;
		letter-spacing: -0.11px;	
	}

	// "Wide style" for Blog Home
	&.wide {
		.sm-text-image-banner__left {
			flex: 0 0 58%;
			max-width: 58%;
		}
		.sm-text-image-banner__right {
			flex: 0 0 42%;
			max-width: 42%;
		}
		
		.sm-text-image-banner__big-heading {
			font-family: $font-solomon;
			font-size: 4rem;
			line-height: 5rem;		
		}

		@media(max-width: $screen-md) {
			&__outer {
				flex-direction: column-reverse;
				align-items: center;
				text-align: center;
			}

			.sm-text-image-banner__desc {
				display: none;
			}
			
			.sm-text-image-banner__big-heading {
				font-size: 3rem;
				line-height: 3.5rem;			
			}

			.btn-group {
				margin-top: 1rem;	
			}
		}
		@media(max-width: $screen-sm) {
			.sm-text-image-banner__left, .sm-text-image-banner__right {
				flex: 0 0 100%;
				max-width: 100%;
			}

			.sm-text-image-banner__small-heading {
				text-align: center;
				u::after {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.sm-text-image-banner__big-heading {
				font-size: 2.4rem;
				line-height: 3rem;
			}
		}
	}
	
	// "Left icon image style" for Single Case
	&.limg {
		.sm-text-image-banner__left, .sm-text-image-banner__right {
			margin: 0;
		}
	
		.sm-text-image-banner__feature-img img {
			display: block;
			max-width: 12rem;
			height: auto;
			margin-right: 2.2rem;
		}
		
		.sm-text-image-banner__big-heading {
			margin: 0;
			font-family: $font-solomon;
		    font-size: 2.4rem;
			line-height: 3.2rem;

			em {
				font-size: 1.3rem;
				letter-spacing: 1.78px;
				line-height: 2.6rem;
			}
		}
		
		.sm-text-image-banner__desc {
			font-size: 3.2rem;
			line-height: 4.1rem;
			margin: 3rem 0;
		}

		@media(max-width: $screen-sm) {
			.sm-text-image-banner__left {
				margin-top: 3rem;
			}
			
			.sm-text-image-banner__image {
				padding: 0;
			}
			
			.sm-text-image-banner__big-heading {
				font-size: 2rem;
				line-height: 3rem;
			}
			
			.sm-text-image-banner__desc {
				max-width: none;
				font-size: 2rem;
				line-height: 3rem;
			}
			
			.btn-group {
				margin-top: 2rem;
				text-align: left;			
			}
		}
	}
}