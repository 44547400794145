.sm-index {
	&__title {
		margin-bottom: 2rem;
	}
	
	&__item {
		margin: 1rem 0;
		
		&__link {
			font-size: 2rem;
		}
		
		&__excerpt {
			font-size: 1.4rem;
			line-height: 2.2rem;
		}
	}
}