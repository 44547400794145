.page-404 {
    max-width: 83rem;
    margin: 12rem auto;

	h1 {
		font-size: 3.5rem;
		line-height: 4.2rem;
		margin-bottom: 2rem;
	}

	p {
		margin-bottom: 2rem;
		font-size: 1.8rem;
		font-weight: 400;
		line-height: 2.4rem;
		letter-spacing: -.11px;	
		text-align: center;
	}
	
	.siteBtn {
		margin-top: 4rem;
	}
}