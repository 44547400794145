/* Popup video player */
.video-player-popup {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	z-index: 20;
	background: rgba(0, 0, 0, .8);

	div {
		width: 80%;
		max-width: 600px;
		border: 2px solid white;
		background: #000;
	}
	
	a {
		position: absolute;
		top: 10px;
		left: 10px;
		
		svg {
			width: 26px;
			height: 26px;
			stroke: white;
			stroke-width: 2px;
		}
	}
}