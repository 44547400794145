// Menu entries that contain icons
#nav__open, #nav__search, #navmobile__search, #navmobile__close {
	display: flex;
	margin: 2rem 0;
	padding: 0rem 2.5rem;
	align-items: center;
	cursor: pointer;
	fill: $white;
	svg {
		vertical-align: middle;
	}
	&:hover, &:focus {
		fill: $pink;
	}
}

// Button to open the mobile menu
#nav__open {
	display: none;				// Default to off in desktop
	svg {
		width: 3rem;
	}
}

// Search buttons
#nav__search, #navmobile__search, #navmobile__close {
	padding: 1.4rem;
	margin: 2rem 0.4rem;
	svg {
		width: 2rem;
	}
}
#navmobile__search {
	float: left;
}
#navmobile__close {
	float: right;
}

.no-js #nav__search,
.no-js #navmobile__search {
	display: none;					// Search requires JavaScript so no need for the icon
}


// Text formatting common to the desktop and mobile menus
.navdesktop__menu > a, .navdesktop__subitem > a, .navmobile a {
	display: block;
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: .86px;
	color: $white;
	cursor: pointer;	
}


// Get In Touch button
#nav__git {
	min-width: 18rem;
	background: 100% 0/300% no-repeat linear-gradient(270deg, #FB7268 0%, $pink 33%, $white 34%, $white 100%) border-box;

	&:hover:not(.disabled) {
		color: $pink;
		border-color: $pink;
		background: $white;
	}
}


.siteHeader {
	position: relative;
	padding: 0 3.2rem;
	background: $off-black;

	@media(max-width: $screen-md) {
		padding: 0 0 0 1rem;					// Remove the right padding so the mobile menu button lines up against the window edge
	}
	
	button {
		background: none;
		border: 0;	
	}
	
	.header_inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	
	// Right-aligned navigation items
	.site_navigation {
		display: flex;
		align-items: center;
	}
	
	// Top-left branding logo
	.site_logo {
		a {
			display: block;
			padding: 0.5rem;
			cursor: pointer;

			img {
				display: block;
				width: 190px;
				height: 57px;
				object-fit: cover;
				@media(max-width: $screen-sm) {		// Trim the logo on phones
					width: 45px;
					height: 55px;
    				object-position: left;
				}
			}
		}
	}
}


.navdesktop {
	// All top-level menu entries
	&__menu {
		display: inline-block;
		padding: 0;
		
		& > a {
			padding: 3.6rem 1.5rem;
			margin: 0;
			outline-offset: -10px;
			@media(max-width: $screen-md) {
				height: 5rem;
				line-height: 5rem;
			}
		}
		&:hover > a, & > a:focus, &.navdesktop--showmenu > a {
			text-decoration: none;
			color: $pink;
		}
		
		&.navdesktop-children {
			& > a::after {
				display: inline-block;
				position: relative;
				content: url('images/dropdown-up.svg');
				width: 0.5rem;
				top: -3px;
				margin-left: 0.7rem;
			}
		}
		&.navdesktop-children:hover > a, &.navdesktop-children > a:focus, &.navdesktop-children.navdesktop--showmenu > a {
			&::after {
				content: url('images/dropdown-up-fill.svg');
			}
		}
	}
		

	// Desktop popup submenu. Common to branded and basic menus
	&__submenu, &__branded {
		display:none;						// Sub-menus are hidden until hover/focus
		position: absolute;
		top: 100%;
		z-index: 10; 
	}
	.no-js &__menu:hover .navdesktop__submenu,
	.no-js &__menu:hover .navdesktop__branded,
	.no-js &__menu:focus .navdesktop__submenu,
	.no-js &__menu:focus .navdesktop__branded,
	&--showmenu .navdesktop__submenu,
	&--showmenu .navdesktop__branded {
		display: block;
	}
	
	// Sub-menu in a basic dropdown menu
	&__submenu {
		background: $off-black;
		min-width: 16rem;
		padding: 1rem 0 0.5rem;
	}

	// Sub-menu item in a basic dropdown menu
	&__subitem {
		text-align: left;

		& a {
			padding: 0.5rem 1rem;
			margin: 1rem 0.5rem;
			text-align: left;
			&:hover, &:focus {
				color: $pink;
			}
		}
	}

	// Branded dropdown menu for products and services.
	&__branded {
		background: #fff;
		left: 0;
		right: 0;
		box-shadow: 0 0 4px #ccc;

		&__list {
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}

		&__inner {
			display: flex;
			height: 100%;
			padding:4rem;
			justify-content: space-between;
			flex-direction: column;
			text-align: center;
			position: relative;
			z-index: 1;
		}
		
		&__title {
			margin: 2rem 0;		
			font-size: 3.2rem;
			line-height: 4rem;
			color: $grey-black;
		}
		
		&__desc {
			font-size: 1.4rem;
			line-height: 2.2rem;
			letter-spacing: -.11px;
			color: $grey;
		}

		&__btns {
			margin-top: 3rem;
		}
		
		&__aimg {
			display: block;
			margin: 0 auto 3rem;
		}
		
		img {
			display: block;
			margin: 0 auto;
			object-fit: contain;
		}
	}

	// Products-specific menu
	.products-menu {
		img {
			height: 8rem;
		}
	}
	
	// Service-specific menu
	.services-menu {
		img {
			width: 9rem;
		}
	}
}


.navmobile {
	display: none;
	position: fixed;
	height: 100vh;
	width: 25rem;
	top: 0;
	bottom: 0;
	right: -300px;
	z-index: 110;
	background: $off-black;
	overflow-y: auto;
	box-shadow: -6px 0px 5px 0px rgba(56, 64, 79, 0.61);

	&__showmenu {
		display: block;
	}
	
	& > ul {
		padding-top: 2rem;
	}

	a {
		position: relative;
		line-height: 4rem;
		height: 4rem;
		padding: 0 2.3rem;
		margin: 0 0.2rem;
		text-align: left;		
		&:hover, &:focus {
			text-decoration: none;
			color: $pink;
		}
	}
	
	&__menu-children > a {
		&::before {
			display: inline-block;
			position: absolute;
			top: 1.2rem;
			right: 2rem;
			width: 0.8rem;
			height: 0.8rem;
			padding: 3.2px;
			border: solid $white;
			border-width: 0 1px 1px 0;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			content: '';
		}				
	}
	&__menu-children > a:hover::before, &__menu-children > a:focus::before {	// Hover over
		border-color: $pink;
	}

	&__submenu {
		display: none;
		padding: 1rem 0 1rem 2rem;
	}

	.navmobile__menu--expand {
		& > a::before {									// Up arrow
			border-width:1px  0 0 1px;
		}
		.navmobile__submenu {
			display: block;
		}
	}
}

// In no-JS mode, display the menu when the menu or it's button gets the focus.
.no-js #nav__open:focus .navmobile, .no-js #nav__open:hover .navmobile,
.no-js .navmobile:focus, .no-js .navmobile:hover, {
	display: block;
	right: 0;

	.navmobile__submenu {
		display: block;
	}
}


// Transition to the mobile menu
@media(max-width: $screen-md) {
	.navdesktop {
		display: none;					// Hide the desktop menu
	}

	#nav__open {
		display: flex;					// Show the mobile menu button
	}
	
	#nav__open, #nav__search {
		padding: 1rem 1.3rem;
		margin: 2rem 0.2rem;
	}
	
	#nav__git {
		position: absolute;			// Get In Touch floats to the right of the site logo
		left: 28.5rem;
	}
}

// Transition to a smaller mobile menu for phones
@media all and (max-width: $screen-sm) {
	#nav__search {
		display: none;					// Search is moved to the mobile popup menu
	}
	#nav__git {						// Get In Touch flots in the centre
		left: 0;
		right: 0;
		max-width: 16rem;
		margin: auto;
		
		div {
			height: 4rem;
			line-height: 4rem;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
