// AFL: This is the greatest level of support, minus SVG. Good for everything except ancient iOS.
// See here: https://css-tricks.com/snippets/css/using-font-face/
@mixin fontface($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: "#{$file}";
    src: url('fonts/#{$name}/#{$file}.eot');
    src: url('fonts/#{$name}/#{$file}.eot?#iefix') format('embedded-opentype'),
    url('fonts/#{$name}/#{$file}.woff2') format('woff2'),
    url('fonts/#{$name}/#{$file}.woff') format('woff'),
    url('fonts/#{$name}/#{$file}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
	font-display: swap;
  }
}

// An underline graphic drawn with a background colour, usually the theme colour.
@mixin sm-common-underline {
	content: '';
	display: block;
	width: 3.8rem;
	height: .2rem;
}


