.sm-enquiry-form {
	@include sm-block-globals;

    padding: 4.7rem 0;

	&__title {
		margin-bottom: 1.5rem;
		font-size: 2.4rem;
		text-align: center;
		color: $grey;
	}
}