.sm-twsi {
	@include sm-block-globals;

    padding: 4.7rem 0;

	h2 {
		margin-bottom: 2rem;
		font-size: 5.4rem;
		line-height: 6.2rem;
		font-weight: 400;
		font-family: $font-solomonLight;
		letter-spacing: -.57px;
		
		@media(max-width: $screen-md) {
			line-height: 5rem;
			font-size: 4rem;			
		}
	}
	
	.sm-row > div {
		flex: 0 0 50%;
		max-width: 50%;

		@media(max-width: $screen-sm) {
			flex: none;
			max-width: 100%;
		}
	}
	
	&__text {
		padding-right: 2rem;
		@media(max-width: $screen-sm) {
			padding-right: 0;
		}
	}
	
	&__desc {
		margin: 1rem 0 3rem;
		font-size: 2.4rem;
		line-height: 3.2rem;	
		letter-spacing: -.11px;

		@media(max-width: $screen-md) {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		@media(max-width: $screen-sm) {
			font-size: 1.6rem;
		}
	}
	
	// Bullet points
	&__bullets {
		li {
			position: relative;
			margin-bottom: 1.5rem;
			padding-left: 2rem;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		
		::after {
		    content: '';
		    display: inline-block;
			position: absolute;
		    left: 0;
		    top: 0.8rem;
		    padding: 3px;
		    font-weight: bold;
		    border: solid $pink;
		    border-width: 2px 2px 0 0px;
		    transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		}
	}
	
	.btn-group {
		margin-bottom: 3rem;
		@media(max-width: $screen-sm) {
			margin-bottom: 0;
		}
	}
	
	&__image {
		position: relative;
		margin: 0 auto;
		padding-left: 2rem;
		
		img {
			display: block;
			width: auto;
			height: auto;
		}

		@media(max-width: $screen-sm) {
			margin: 1rem auto;
			padding-left: 0;
		}
		
		.video-popup {
			display: block;
			position: relative;
		}
		
		.play-btn {
			position: absolute;
			width: 64px;
			height: 64px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	&__circle {
		display: flex;
		position: absolute;
		right: 0;
		top: 0;
		width: 26rem;
		height: 26rem;
		padding: 1rem 2rem;
		font-size: 1.4rem;
		line-height: 2.2rem;
		letter-spacing: -.11px;
		border-radius: 50%;
		text-align: center;
		overflow: hidden;
		flex-direction: column;
		justify-content: center;

		@media(max-width: $screen-sm) {
			display: none;
		}
	}
	
	// "small" is styled for the About Us page
	&--small {
		u {
			font-size: 1.4rem;
			line-height: 2.6rem;
			letter-spacing: 1.78px;
		}
		
		h2 {
			font-family: $font-solomon;
			font-size: 4rem;
			line-height: 5rem;
			letter-spacing: -.32px;
		}
	
		.sm-twsi__desc {
			margin-top: 0;
			font-size: 1.8rem;
			line-height: 2.6rem;		
			letter-spacing: -.11px;
		}
	}

	// "vsmall" is styled for the Case Study page
	&--vsmall {
		u {
			font-size: 1.4rem;
			line-height: 2.6rem;
			letter-spacing: 1.78px;
		}
		
		h2 {
			font-family: $font-solomon;
			font-size: 4rem;
			line-height: 5rem;
			letter-spacing: -.32px;
		}
	
		.sm-twsi__desc {
			margin-top: 0;
			font-size: 1.4rem;
			line-height: 2.2rem;
			letter-spacing: -.11px;
		}
	}
	
	&--rev {
		> .sm-container > .sm-row {
			flex-direction: row-reverse;
		}
		
		.sm-twsi__circle {
			left: 0;
			right: auto;
		}

		.sm-twsi__text {
			padding-left: 2rem;
			@media(max-width: $screen-sm) {
				padding-left: 0;
			}
		}

		.sm-twsi__image {
			padding-right: 2rem;
			@media(max-width: $screen-sm) {
				padding-right: 0;
			}
		}		
	}
	
	// Small right-aligned icon
	&__ricon {
		position: relative;
		top: -4rem;
		margin: 1rem;
		float: right;
		
		img {
			max-width: 9.3rem;
			max-height: 10.3rem;
		}
		@media(max-width: $screen-md) {
			top: -2rem;
			img {
				max-width: 7rem;
			}
		}
	}
}