.sm-sign-up {
	@include sm-block-globals;

	padding: 3.2rem 0;

	&__newsletter {
		max-width: 52rem;
	    margin: 2.5rem auto 0;

	    br {
			display:none;
		}

	    input:not([type=submit]) {
	    	height: 6rem;
	    	padding: 0 5rem 0 3rem;
	    	border-radius: 0.4rem;
    	    border: none;
	    	font-size: 1.8rem;

  			@media (max-width: $screen-sm) {
	    		height: 5rem;
	    		padding: 0 1.5rem;
	    		font-size: 1.6rem;
  			}
	    }

/*	    .ajax-loader {*/
		.wpcf7-spinner {
	    	position: absolute;
    	    bottom: 3.5rem;
	    }

	    .submit-key {
	    	position: relative;
			text-align: center;
	    }

	    input[type=submit] {
	 	    width: auto;
  	 		margin: 2.5rem 0 2rem;
	    }
	}

	&__terms {
		margin: 0;
		font-size: 1.2rem;
		line-height: 2.2rem;
		letter-spacing: -.1px;
		color: #486175;
	}
}