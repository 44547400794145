// Images url

$assetPath: "./images";

// Colors
$black: #000;
$white: #fff;
$off-white: #f8f8f8;
$off-white1: #f4f4f4;
$off-black: #38404F;
$grey-black: #486175;
$porcelain: #EFF2F3;
$grey: #444A5F;
$lightgrey-2: #dddddd;
$pink: #E82B7A;
$blue: #00A7EE;
$green: #05CFAB;
$purple: #800080;


// Fonts
$font-solomon: "Solomon-Sans-Normal";
$font-solomonLight: "Solomon-Sans-light";
$font-solomonSemi: "Solomon-Sans-SemiBold";
//$font-solomonBold: "Solomon-Sans-Bold";				Font is no longer used. Removed from _fonts.scss


// Screen size
$screen-sm: 767px;
$screen-md: 1024px;
// $screen-lg: 1199px;									Size is no longer used.
