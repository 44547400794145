// Buttons common to all pages
//	Primary class:	siteBtn
//	Thin border:		stroke-1
//	Size adjustments:	btn-small, btn-v-small
//	Colour selector:	theme-1, etc
//	Disabled:			disabled
//	Active:			active
.siteBtn {											// Wrap in body to allow overriding of CF7 input buttons
	display:			inline-block;
	position:			relative;
	min-width: 		22rem;
	min-height:		5.5rem;
	padding:			0 2rem;
	border:			0.2rem solid transparent;
	border-radius: 	8rem;
	letter-spacing: 	1.14px;
	overflow: 			hidden;
	font-family:		$font-solomonSemi;
	font-size:			1.6rem;
	line-height: 		5.1rem;
	letter-spacing: 	0.86px;
	text-transform:	uppercase;
	text-align:		center;
	transition:		none;
	cursor:			pointer;

    @media(max-width: $screen-md) {
		min-width: 	20rem;
		min-height:	4rem;
        line-height:	3.8rem;
		padding:		0 3.5rem;
        font-size:		1.2rem;
        border-width:	0.1rem;
    }

    @media(max-width: $screen-sm) {
		min-width: 	18rem;
        padding:		0 3rem;
    }
	
	&.disabled {
		cursor: default;
	}

	&.active {
	}

	// Apply a transition on hover but not before. This prevents buttons from animating changes to their width.
/*	&:hover {
		transition: 0.45s;
	}*/

	&:hover, &:focus {
		text-decoration: none;
//		outline: none;
	}

	.user-is-tabbing &:focus {
//		outline: auto 2px Highlight;
//		outline: auto 5px -webkit-focus-ring-color;
		outline-offset: 0.4rem;
	}

    &.stroke-1 {
		border-width: 0.1rem;
    }
	
	// A small version of the standard button that has the same width
    &.btn-small {
		min-height: 4rem;
		line-height: 3.8rem;
		font-size: 1.2rem;
		@media(max-width: $screen-md) {
			min-height: 4rem;
			line-height: 3.8rem;
		}
    }

	&.btn-narrow {
		min-width: 12rem;
	}
	&.btn-narrowest {
		min-width: 0rem;
	}


	&--theme-1 {
		&.fill {		// filled button on any background
			background: 100% 0/300% no-repeat linear-gradient(270deg, #FB7268 0%, $pink 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: transparent;
			color: $white;
			&:hover:not(.disabled) {
				border-color: $off-black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($white, 0.5);//color.change($white, $alpha: 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}
		
		&.outline {	// transparent button on a light background
			background: 100% 0/300% no-repeat linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, #FB7268 66%, $pink 100%) border-box;
			border-color: $pink;
			color: $pink;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($pink, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: 100% 0/300% no-repeat linear-gradient(270deg, $white 0%, $white 65%, $off-black  66%, $off-black  100%) border-box;
			border-color: $pink;
			color: $pink;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($pink, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}
	}
	
	&--theme-2 {
		&.fill {		// filled button on any background
			background: 100% 0/300% no-repeat linear-gradient(270deg, #18E1E9 0%, $blue 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: transparent;
			color: $white;
			&:hover:not(.disabled) {
				border-color: $off-black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($white, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.outline {	// transparent button on a light background
			background: 100% 0/300% no-repeat linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, #18E1E9 66%, $blue 100%) border-box;
			border-color: $blue;
			color: $blue;
			&:hover:not(.disabled) {
				border-color: $blue;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($blue, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: 100% 0/300% no-repeat linear-gradient(270deg, $white 0%, $white 65%, $off-black  66%, $off-black  100%) border-box;
			border-color: $blue;
			color: $blue;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($blue, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}
	}

	&--theme-3 {
		&.fill {		// filled button on any background
			background: 100% 0/300% no-repeat linear-gradient(270deg, #18E1E9 0%, $green 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: transparent;
			color: $white;
			&:hover:not(.disabled) {
				border-color: $off-black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($white, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.outline {	// transparent button on a light background
			background: 100% 0/300% no-repeat linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, #18E1E9 66%, $green 100%) border-box;
			border-color: $green;
			color: $green;
			&:hover:not(.disabled) {
				border-color: #04a78a;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($green, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: 100% 0/300% no-repeat linear-gradient(270deg, $white 0%, $white 65%, $off-black  66%, $off-black  100%) border-box;
			border-color: $green;
			color: $green;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($green, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}
	}

	&--theme-4 {
		&.fill {		// filled button on any background
			background: 100% 0/300% no-repeat linear-gradient(270deg, #fe92fe 0%, $purple 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: transparent;
			color: $white;
			&:hover:not(.disabled) {
				border-color: $off-black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($white, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.outline {	// transparent button on a light background
			background: 100% 0/300% no-repeat linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, #fe92fe 66%, $purple 100%) border-box;
			border-color: $purple;
			color: $purple;
			&:hover:not(.disabled) {
				border-color: #04a78a;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($purple, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: 100% 0/300% no-repeat linear-gradient(270deg, $white 0%, $white 65%, $off-black  66%, $off-black  100%) border-box;
			border-color: $purple;
			color: $purple;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($purple, 0.5);
			}
			&.active {
				color: $white;
				background-position: 0 0;
			}
		}
	}

	&.white-btn,
	&--white {
		&.fill {
			background: 100% 0/300% no-repeat linear-gradient(270deg, #fff 0%, #fff 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: transparent;
			color: $off-black;
			&:hover:not(.disabled) {
				border-color: $off-black;
				color: $white;
				background-position: 0 0;				
			}
			&.disabled {
				color: fade-out($green, 0.5);
			}
		}
		
		&.outline {
			background: 100% 0/300% no-repeat linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33%, #fff 34%, #fff 100%) border-box;
			border-color: $white;
			color: $white;
			&:hover:not(.disabled) {
				color: $off-black;
				background-position: 0 0;				
			}
			&.disabled {
				color: fade-out($green, 0.5);
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: 100% 0/300% no-repeat linear-gradient(270deg, $white 0%, $white 33%, $off-black 34%, $off-black 100%) border-box;
			border-color: $white;
			color: $off-black;
			&:hover:not(.disabled) {
				color: $white;
				background-position: 0 0;				
			}
			&.disabled {
				color: fade-out($green, 0.5);
			}
		}
	}

	&--greyblack {
		&.fill {
			background: $grey-black;
			color: $white;
			&:hover:not(.disabled) {
				background: $off-black;
				border-color: $black;
			}
			&.disabled {
				color: fade-out($white, 0.5);
			}
			&.active {
				background: $off-black;
			}
		}

		&.outline {
			border-color: $grey-black;
			color: $grey-black;
			&:hover:not(.disabled) {
				background: $off-black;
				border-color: $black;
				color: $white;
			}
			&.disabled {
				color: fade-out($grey-black, 0.5);
			}
			&.active {
				background: $off-black;
				color: $white;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: $white;
			border-color: $grey-black;
			color: $grey-black;
			&:hover:not(.disabled) {
				background: $off-black;
				border-color: $black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($grey-black, 0.5);
			}
			&.active {
				background: $off-black;
				color: $white;
				background-position: 0 0;
			}
		}
	}

	&--offblack {
		&.fill {
			background: $off-black;
			color: $white;
			&:hover:not(.disabled) {
				background: $black;
				border-color: $black;
			}
			&.disabled {
				color: fade-out($white, 0.5);
			}
			&.active {
				background: $black;
			}
		}

		&.outline {
			border-color: $off-black;
			color: $off-black;
			&:hover:not(.disabled) {
				background: $black;
				border-color: $black;
				color: $white;
			}
			&.disabled {
				color: fade-out($off-black, 0.5);
			}
			&.active {
				background: $black;
				color: $white;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: $white;
			border-color: $off-black;
			color: $off-black;
			&:hover:not(.disabled) {
				background: $black;
				border-color: $black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($off-black, 0.5);
			}
			&.active {
				background: $black;
				color: $white;
				background-position: 0 0;
			}
		}
	}


	// Button for toggle states (cookie popup, blog category selectors)
	&--porcelain {
		&.fill {
			background: $porcelain;
			color: $grey-black;
			&:hover:not(.disabled) {
				background: #cbe3e8;
				border-color: $porcelain;
				color: $grey-black;
			}
			&.disabled {
				color: fade-out($grey-black, 0.5);
			}
			&.active {
				background: $grey-black;
				color: $white;
			}
			&.active:hover:not(.disabled) {
				background: #4c7a9e;
				border-color: $grey-black;
				color: $white;
			}
		}

		&.outline {
			border-color: $porcelain;
			color: $grey-black;
			&:hover:not(.disabled) {
				background: $grey-black;
				border-color: $black;
				color: $white;
			}
			&.disabled {
				color: fade-out($grey-black, 0.5);
			}
			&.active {
				background: $grey-black;
				color: $white;
			}
		}

		&.alt-fill {	// non-transparent button for dark backgrounds
			background: $white;
			border-color: $porcelain;
			color: $grey-black;
			&:hover:not(.disabled) {
				background: $grey-black;
				border-color: $black;
				color: $white;
				background-position: 0 0;
			}
			&.disabled {
				color: fade-out($grey-black, 0.5);
			}
			&.active {
				background: $grey-black;
				color: $white;
				background-position: 0 0;
			}
		}
	}
}

// Horizontal list of buttons that transitions to a vertical list on small screens
.btn-group {
	flex-wrap: wrap;
//	width: 100%;				// Why was this here? Causes button to offset in cookie popup
	margin: 0 -1rem; 

	@media(max-width: $screen-md) {
		margin: 0 -0.5rem; 
	}

	@media(max-width: $screen-sm) {
		text-align: center;
	}

    > * { 
		margin: 0.2rem 1rem; 

		@media(max-width: $screen-md) {
			margin: 0.2rem 0.5rem;
		}
		@media(max-width: $screen-sm) {
			margin-top: 1.2rem;
		}
    }
}
