.sm-standard-banner {
	@include sm-block-globals;

	display: flex;
	position: relative;
	min-height: 50rem;
	flex-wrap: wrap;
	align-items: center /*!important*/;
	background-size: cover;
	overflow: hidden;

	@media(max-width: $screen-md) {
		min-height: 40rem;
	}

	.sm-container {
		text-align: center;
		z-index: 2;
	}

	&__icon {
		margin-bottom: 2.5rem;
		img {
			display: block;
			width: 8rem;
			height: 8rem;
			margin: 0 auto;
		}
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 6rem;
		line-height: 7rem;	
	    font-weight: 400;
		color: $white;
		em {
			color: $off-black;			
		}
		@media(max-width: $screen-md) {
			font-size: 5rem;
			line-height: 6rem;		
		}
		@media(max-width: $screen-sm) {
			font-size: 3.2rem;
			line-height: 3.6rem;		
		}
	}

	&__desc {
		max-width: 73rem;
		margin: 0 auto;
		font-size: 2.4rem;
		line-height: 3.2rem;
		letter-spacing: -.11px;
		color: $white;

		@media(max-width: $screen-md) {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		@media(max-width: $screen-sm) {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}
	
	&__btns {
		margin-top: 4rem;
		a {
			min-width: 20rem;
			
			& + a {
				margin-left: 2rem;
			}
		}
		@media(max-width: $screen-md) {
			a {
				min-width: 16rem;
			}
		}
		@media(max-width: $screen-sm) {
			padding-top: 2rem;
		}
	}
	
	// Coloured SalesMethods logo floating on the right
	&--smlogo {
		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 50%;
			background: url('images/s-mix.png') no-repeat right top/contain;
			bottom: 3rem;
			@media(max-width: $screen-sm) {
				display:none;
			}
		}
	}
	
	// Blue SalesMethods logo floating on the left, with text aligned to the left
	&--bluelogo {
		min-height: 65rem;

		@media(max-width: $screen-md) {
			min-height: 50rem;
		}
		@media(max-width: $screen-sm) {
			min-height: 40rem;
		}

		&::after {
			content: '';
			position: absolute;
			left: -13rem;
			right: 0;
			top: -30rem;
			bottom: 0;
			height: 113rem;
			transform: rotate(-2deg);
			background: url('images/blue_S.svg') no-repeat left top/contain;

			@media(max-width: $screen-md) {
				top: -27rem;
				width: 92%;
				transform:unset;
				height: 120rem;
			}

			@media(max-width: $screen-sm) {
				height: 83rem;
				width: 61rem;
				top: -22rem;
				left: -22rem;
				transform: rotate(2deg);
			}
		}

		.sm-container {
		    margin-left: 8rem;
			text-align: left;
			@media(max-width: $screen-sm) {
				margin: 0 auto;
				text-align: center;
			}
		}
		
		.sm-standard-banner__desc {
			margin: 0;
			font-size: 3.2rem;
			line-height: 4rem;
			letter-spacing:-0.26px;
			color: $white;
			
			@media(max-width: $screen-sm) {
				margin: 0 auto;
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}
	}
}