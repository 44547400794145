.sm-post-scroller {
	@include sm-block-globals;

	padding: 4.7rem 0;

	&__cats {
		display: flex;
		margin: 1rem -0.5rem 2rem;
		flex-wrap: wrap;
		justify-content: center;
		
		li {
			margin: 0.5rem;
		}
	}

	.sm-post-scroller__title {
		font-size: 2.4rem;
		line-height: 3.2rem;
		text-align: center;
		color: $grey-black;
	}
	
	.sm-post-scroller__posts {
		display: flex;
		margin: 0 -2rem;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	.sm-post-scroller__post {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;			
		margin: 2rem auto;
		padding: 0 2rem;

		@media(max-width: $screen-md) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media(max-width: $screen-sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
		
	.sm-post-scroller__inner {
		display: flex;
		position: relative;
		height: 100%;
		width: 100%;
		padding: 1rem;
		flex-direction: column;			
		background: $white;
		border-radius: 0.6rem;
		box-shadow: 0 2px 11px 0 rgba(72,97,117,0.3);
	}

	.sm-post-scroller__btns {
		padding-top: 1rem;
		text-align: center;
	}
}


.sm-post-scroller__blog {
	.sm-post-scroller__img img {
		display: block;
		height: 23rem;
		margin: 0 auto;
	}

	.sm-post-scroller__text {
		display: flex;
		height: 100%;
		padding: 0 1.5rem;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		text-align: center;

		&__cat
		{
			margin: 1.2rem 0;
			font-family: $font-solomonSemi;
			font-size: 1.2rem;
			line-height: 2.6rem;
			letter-spacing: 1.33px;
			text-transform: uppercase;
			color: $green;
		}

		h3 {
			margin-bottom: 2rem;
			font-family: $font-solomonSemi;
			font-size: 1.8rem;
			line-height: 2.6rem;
			letter-spacing: -.32px;
		}

		&__btn {
			margin-bottom: 2rem;
		}

		&__author {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			span {
				font-size: 1.2rem;
				line-height: 2.6rem;
				color: $grey-black;
			}
		}
	}
}

.sm-post-scroller__case {
	.sm-post-scroller__img {
		display: flex;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		flex: 0 0 22rem;
	
		img {
			display: block;
			height: auto;
			width: auto;
			margin: auto;
			max-height: 18rem;
			max-width: 21rem;
		}
		
		@media(max-width: $screen-sm) {
			flex: 0 0 16rem;
		}
	}

	.sm-post-scroller__text {
		display: flex;
		padding: 0 1.5rem;
		flex-grow: 1;		
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		text-align: center;
	}
	
	h3 {
		min-height: 8rem;
		margin-bottom: 2.2rem;
		font-family: $font-solomonSemi;
	    font-size: 1.6rem;
		line-height: 2.4rem;
		letter-spacing: -.32px;
		color: $grey-black;
		
		@media(max-width: $screen-sm) {
			min-height: auto;
		}
	}

	.sm-post-scroller__name {
		margin: 1.2rem 0;
		font-family: $font-solomonSemi;
		font-size: 1.2rem;
		line-height: 2.6rem;
		letter-spacing: 1.33px;
		text-transform: uppercase;
		color: $green;
	}

	.sm-post-scroller__des {
		font-size: 1.2rem;
		line-height: 2.6rem;
		color: $grey-black;
	}

	.sm-post-scroller__btn {
		margin-bottom: 7rem;
		
		@media(max-width: $screen-sm) {
			margin-bottom: 2rem;
		}
	}
}

