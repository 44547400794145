.footer {
	background: url('images/triangle-black.jpg') no-repeat center/cover;
    margin-top: auto;				// Force footer to bottom on short content

	&__menu {
		display: flex;
		padding: 4rem 0;
		flex-wrap: wrap;
		justify-content: center;
	
		ul {
			min-width: 30rem;
			@media(max-width: $screen-md) {
				min-width: 22rem;
			}
			li a {
				display: block;
				padding: 0.5rem 0;
				text-align: center;
				opacity: 0.75;
				color: $white;
				&:hover{
					opacity: 1;
				}
			}
		}

		@media(max-width: $screen-sm) {
			padding: 3rem 0;	
		}
	}
	
	&__desktop-only {
		@media(max-width: $screen-sm) {
			display: none;
		}
	}

	&__socialicons {
		display: flex;
		margin: 1rem 0 1rem;
		justify-content: center;

		a {
			display:	inline-block;
			padding:	0.5rem;
			margin:	0.3rem;
			font-size:	2.3rem;
			color: 	$white;
			fill:		$white;
	    	opacity:	1;
			&:hover {
				opacity: 0.75;
			}
		}
		
		svg {	
			width: 2.3rem;
		}
	}

	&__socialtext {
		padding: 			0 0 2rem 0;
		font-size: 		1.2rem;
		text-align:		center;
		letter-spacing:	0;
		color: 			$white;
		@media(max-width: $screen-sm) {
			max-width:	20rem;
			margin: 	auto;
		}
	}
}

.footer-appex {
	display: flex;
	padding-top: 5rem;
	padding-bottom: 5rem;
    justify-content: center;
    align-items: center;	

	&__title {
		max-width: 37rem;
		padding-bottom: 1rem;
		letter-spacing: -0.24px;
		font-size: 3rem;
		font-weight: 400;
		text-align: center;
		color: $grey-black;
		b {
			font-weight: normal;
			color: $pink;
		}
	}

	&__tiles {
		display: flex;
		margin-top: 1rem;
		
		img {
			display: block;
			width: 20rem;
			margin: 1rem;
			
			@media(max-width: $screen-sm) {
				margin: 1rem auto;
			}
		}
		& a:hover img {
			transform: scale(1.5);
			transition: 0.5s;
		}
	}
	
	// Vertically stack the title
	@media(max-width: $screen-md) {
		display: block;

		&__title {
			max-width: unset;
			width: 100%;
			padding-bottom: 1rem;
			text-align: center;
		}
		
		&__tiles {
		    justify-content: center;
		}
	}

	// Vertically stack the title and tiles
	@media(max-width: $screen-sm) {
		&__title {
			font-size: 2.4rem;
			line-height: 3.2rem;
		}
		
		&__tiles {
			display: block;
			a {
				display: block;
				text-align: center;
			}
		}
	}
}

// Cookie popup banner
.cookie-notice {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100000;
	font-size: 13px;
	padding: 10px;
	line-height: 20px;
	text-align: center;
    border-top: 2px solid white;
	background: $off-black;
	
	p {
		color: $white;
	}
	
	a {
		text-decoration: underline;
	}

	button {
		margin: 10px 0;
		padding: 4px 15px;
		box-shadow: none;
		border:none;
		background: $white;
		cursor: pointer;
	}
}


