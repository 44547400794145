.sm-blog-slider {
	@include sm-block-globals;

	padding: 4.7rem 0;
	text-align: center;
//	color: $white;
//	background: $off-white;

	&__item {
		max-width: 85rem;
		margin: 0 auto;	
	}
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		margin: 0.5rem 0;
		padding: 1.5rem;
		box-shadow: 0 2px 4px 2px rgba(0,0,0,.2);	
	}
	
	&__img {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;	

		img {
			display: block;
			height: 23rem;
			width: 100%;	
			object-fit: cover;
			object-position: top;
		}
		
		@media(max-width: $screen-md) {
			flex: none;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	
	&__text {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;	
		padding: 2rem 3rem 1rem;
		text-align: left;

		@media(max-width: $screen-md) {
			flex: none;
			width: 100%;
			max-width: 100%;
			text-align: center;
		}
	}
	
	&__cats {
		font-size: 1.6rem;
		text-transform: uppercase;
		margin-bottom: 2rem;
		letter-spacing: 1.78px;	
		
		&::after {
			@include sm-common-underline;
			margin: 1.5rem 0 0;

			@media(max-width: $screen-md) {
				margin: 1.5rem auto 0;
			}
		}		
	}
	
	&__title {
		margin: 0 0 3rem;
		font-size: 2.4rem;
		line-height: 3.2rem;
		letter-spacing: 0;
		font-weight: 400;

		@media(max-width: $screen-md) {
			font-size: 1.4rem;
			line-height: 2rem;
		}
	}
	
	&__btn {
	    text-align: right;
		@media(max-width: $screen-md) {
			text-align: center;
		}
	}
}