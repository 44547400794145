.sm-review-slider {
	@include sm-block-globals;

	padding: 4.7rem 0;
	text-align: center;
	color: $white;

	.slick-slider {
	    min-height: 11.2rem;
		line-height: 2.2rem;
		font-family: Solomon-Sans-SemiBold;
		font-size: 1.6rem;
	}

	&__rating {
		margin-bottom: 1.6rem;
		span {
			display: inline-block;
			width: 2.1rem;
			height: 2.1rem;
			margin: 0.4rem;
			background: url('images/star.svg');
		}
	}

	&__title, &__pos {
		font-size: 1.4rem;
		line-height: 2rem;
		margin-top: 2rem;
	}

	&__pos {
		font-size: 95%;
		color: #ddf5f9;
	}
}