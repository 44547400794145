.sm-people {
	@include sm-block-globals;

	padding: 4.7rem 0;
	background: url('images/keyfeature-bg-black.jpg') no-repeat center/cover;
	
	// Introduction text
	&__intro {
		max-width: 70rem;
	    margin: 0 auto 4.5rem;
		text-align: center;

		h2 {
		    font-family: Solomon-Sans-light;
			color: $white;
		}

		p {
			font-size: 1.6rem;
			color: $white;
		}
	}

	// Team collection
	&__team {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: space-evenly;
		gap: 1rem;

		// Individual team member with title, image, and text.
		&__item {
			position: relative;
			width: 28rem;
			margin-bottom: 2rem;
			padding-bottom: 5rem;
			text-align: center;

			// Name
			h3 {
				font-size: 2.4rem;
				line-height: 3.2rem;
				font-weight: 400;
				color: $white;
				
				// Job title
				small {
					display: block;
					margin: 0.5rem 0;
					font-size: 1.4rem;
					color: $green;
				}
			}
		}

		// Profile image
		&__img {
			margin-bottom: 2.5rem;
			img {
				display: block;
				width: 17.3rem;
				height: 17.3rem;
				object-fit: cover;
				margin: 0 auto;
			}
		}

		// Person detail text
		&__detail {
			color:$white;
		}

		// LinkedIn button bar
		&__social {
			position: absolute;
			bottom: 1rem;
			left: 0;
			right: 0;

			a {
				opacity: 0.5;
				&:hover {
					opacity:1;
				}
			}
			
			svg {
				width: 2.5rem;
				height: 2.5rem;
				fill: $white;
			}
		}
	}
}