.sm-office-maps {
	@include sm-block-globals;

	.organization-name {
		display: none;
	}
	
	> .sm-container > .sm-row {
		margin: 0 -2rem;
	}
	
	&__address {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 2rem;
	
		@media(max-width: $screen-sm) {
			flex: 0 0 100%;
			max-width: 100%;
			
			+ .sm-office-maps__address {
				margin-top: 3rem;
			}
		}
		&:nth-child(odd) {
			& > div { margin-right: 1.5rem; }
			.sm-office-maps__right-location a { color:$green; }

		}
		&:nth-child(even) {
			& > div { margin-left: 1.5rem; }
			.sm-office-maps__right-location a { color:$blue; }
		}
	}

	&__address-box {
		display: flex;
		flex-wrap: nowrap;
		padding-top: 2rem;
		justify-content: space-between;
		@media(max-width: $screen-md) {
			display: block;
			padding-top:0;
			& > div {
				width: 100%;
				text-align: left;
			}
		}
	}

	&__left-location {
		h3 {
			font-size: 2.4rem;
		}
		
		span {
			display: block;
			font-size: 1.6rem;
		}
	}
			
	&__right-location {
		p {
			font-size: 2rem;
			line-height: 3rem;
			text-align: right;
			@media(max-width: $screen-md) {
				line-height: 2.8rem;
				font-size: 1.8rem;
				text-align: left;
			}
		}
		
		span {
			display: block;
		}
	}

	&__map {
		margin-top: 1rem;
		padding-bottom: 2rem;
		-webkit-filter: grayscale(50%);
		filter: grayscale(50%);

		iframe {
			display: block;
			width: 100%;
			height: 40rem;
			@media(max-width: $screen-md) {
				height: 30rem;
			}
			@media(max-width: $screen-sm) {
				height: 24rem;
			}
		}
	}
}