.sm-contact-banner {
	@include sm-block-globals;

	position: relative;
	padding: 4.2rem 0;
	background: linear-gradient(215.34deg, rgba(0, 167, 238, 0.09), $white);

	&::after{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;	
		content:'';
		background: url('images/bottom-gradient.jpg') no-repeat right bottom/contain;
	}

	.sm-container {
		position: relative;
		z-index: 2;
	}
	
	.sm-row {
		align-items: center;
		justify-content: space-between;
	}

	&__left, &__right {
		flex: 0 0 50%;
		max-width: 50%;

		@media(max-width: $screen-sm) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&__left {
		// Main heading on the left side
		h1 {
			margin-bottom: 1.5rem;
			font-family: inherit;
			font-size: 5.4rem;
			line-height: 6.2rem;
			font-weight: 400;
			letter-spacing: -.57px;			
			color: $pink;

			@media(max-width: $screen-md) {
				font-size: 4rem;
			}
			@media(max-width: $screen-sm) {
				font-size:3.5rem;
			}
		
			// "Get in touch for answers!" text
			small {
				display: block;
				margin: 2rem 0;
				font: 600 3.5rem $font-solomonLight;
				color: $grey-black;
				@media(max-width: $screen-md) {
					font-size: 2.5rem;
				}
				@media(max-width: $screen-sm) {
					font-size: 1.8rem;
				}
			}
		}
	}

	// Body text on left
	&__desc p {
		line-height: 3.2rem;
		font-size: 2.4rem;
		color: $grey-black;
		a {
			font-weight: 600;
		}
		@media(max-width: $screen-md) {
			line-height: 2.6rem;
			font-size: 2rem;
		}
		@media(max-width: $screen-sm) {
			line-height: 2.2rem;
			font-size: 1.6rem;
		}
	}
	
	// Generic image on the left
	&__image {
		display: block;
		height: auto;
		margin-top: 10rem;
		@media(max-width: $screen-sm) {
			margin: 4rem auto 3rem;
		}
	}
}
