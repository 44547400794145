.sm-image-features {
	@include sm-block-globals;

	padding: 4.7rem 0;
	
	&__image {
		text-align: center;
	}

	&__image {
		img {
			display: block;
			height: auto;
			width: auto;
			margin: 0 auto;
			object-fit: cover;

			@media(max-width: $screen-sm) {
				max-height: unset !important;		// Override the inline style
			}
		}
	}
	
	.sm-row {
		margin: 0 -2rem;
	}
	
	&__feature {
		margin-bottom: 2.5rem;
		padding: 0 2rem;
		text-align: center;
		
		img {
			display: block;
			width: auto;
			margin: 0 auto;
			object-fit: cover;
		}

    	@media(max-width: $screen-sm) {
			flex: 0 0 100%;
		}
	}
	
	h3 {
		padding: 1rem 0;
		font-size: 2.4rem;
		font-weight: 400;
		line-height: 3.2rem;
		letter-spacing: 0;

    	@media(max-width: $screen-sm) {
			font-size: 2rem;
			line-height: 3rem;
		}
	}
	
	&__desc {
		font-size: 1.4rem;
		line-height: 2.2rem;
		letter-spacing: -.11px;
	}
}