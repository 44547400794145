
.sm-container {
	margin: 0 auto;
	max-width: 1450px;

	@media(max-width: 1600px) {
		max-width: 92%;
	}
	@media(max-width: $screen-md) {
		max-width: 94%;
	}
	@media(max-width: $screen-sm) {
		max-width: 96%;
	}
}

.sm-row {
    display: flex;
    flex-wrap: wrap;
}

// Column wrapping preference. Get with a call to getFlexColumnClass.
.sm-flex-col2 { flex: 1 1 49%; }
.sm-flex-col3 { flex: 1 1 33%; @media(max-width: $screen-sm) { flex: 1 1 100%; } }
.sm-flex-col4 { flex: 1 1 24%; @media(max-width: $screen-md) { flex: 1 1 49%; } }



// Horizontal list of breadcrumb items, separated by slash characters.
.site-breadcrumbs {
    display: flex;
	margin: 3.2rem 0;
    flex-wrap: wrap;

	li {
		display: inline-block;
	
		a {
			padding-right: 2rem;
			color: $grey-black;
			&:hover{
			  color: $pink;
			}
		}
		&:not(:last-child)::after {
			content: '/';
			margin-right: 2rem;
		}
	}
}



// In-page navigation anchor displayed as a down arrow
.scroll-icon2 {
	display: none;					// Default to hidden until 'show' is set from index.js
	position: absolute;
	bottom: 1rem;
	left: 45%;						// Prevent the window overlapping controls
	right: 45%;
	z-index: 10;
	text-align: center;
	
	&.show {
		display: block;
	}

	a {
		display:	inline-block;
		width:		4rem;
		height:	4rem;
		padding:	1rem 0;
		cursor:	pointer;
		outline:	none;
	}

	.down-icon {
		display: inline-block;
		padding: 5px;
		border: solid $green;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
	&--white .down-icon 			{ border-color: $white; } 
	&--greyblack .down-icon		{ border-color: $grey-black; } 
	&--offblack .down-icon		{ border-color: $off-black; }
	&--theme-1 .down-icon			{ border-color: $pink; }
	&--theme-2 .down-icon			{ border-color: $blue; }
	&--theme-3 .down-icon			{ border-color: $green; }
	&--theme-4 .down-icon			{ border-color: $purple; }
}



.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}


// Full-screen modal popup
.custom-modal {
    display: 		flex;
	position:		fixed;
	top:			0;
	left:			0;
	bottom:		0;
	right:			0;
	align-items: 	center;
    justify-content: center;
	background:	rgba(56,64,79,0.9);
	z-index:		9999;

	// Close button
	&__close {
		position:	absolute;
		right:		5rem;
		top:		3rem;
		height:	3rem;
		width:		3rem;
		cursor:	pointer;
		
		svg {
			width:		3rem;
			height:	3rem;
			stroke:	$white;
			stroke-width: 2px;
			&:hover, &:focus {
				stroke: $pink;
			}
		}
	}
}


// Social sharing icons common to the blog and case pages
.socialshare {
	display: flex;

	li {
		margin-right: 1rem;
	}
	li:last-child {
		margin-right: 0;
	}

	a {
		display: inline-flex;
		height: 4rem;
		width: 4rem;
		align-items: center;
	}

	svg {
		width: 2rem;
		margin: 0 auto;
		fill: $white;
	}
	
	a:hover, a:focus {
		opacity: 0.7;
	}

	&-facebook { background: #3c5a98; }
	&-twitter { background: #4199fb; }
	&-linkedin { background: #277cb6; }
	&-email { background: #f7b632; }

	&__btn {
		display: inline-block;	
		position: relative;
	}

	&__popup {
		display: none;
		position: absolute;
		padding: 2rem;
		left: 0;
		border-radius: 0.8rem;
		box-shadow: 0 0 4px #333;
		background: $white;

		&__close {
			display: flex;
			position: absolute;
			width: 2rem;
			height: 2rem;
			right: -1rem;
			top: -1rem;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 1.4rem;
			line-height: 20px;
			box-shadow: 0 0 4px #333;
			color: #191818;
			background: #fff;
			cursor: pointer;
			
			&:hover, &:focus {
				text-decoration: none;
				box-shadow: 0 0 4px #333;
			}
		}
	}
}


// Common background image
.sm-overlay {
	background-size: cover;
}

.sm-dark-overlay {
	background-size: cover;

	&::before {
		content:'';
		position:absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: $off-black;
		opacity: 0.7;
	}

/*	// Push children above the overlay stacking order
	> * {
		position: relative;
	}*/
}



// Common title and description used on most blocks
.sm-common-title {
	margin: 0 auto 3rem;
    text-align: center;
	position: relative;

	&--small {
		h2 {
		    margin-bottom: 1.5rem;
			font-size: 1.6rem;
			letter-spacing: 1.78px;
			line-height: 2.6rem;
			font-weight: 400;
		}

		.sm-common-title__desc {
			margin-bottom: 1rem;
			font-size: 1.5rem;
			line-height: 2.2rem;
			letter-spacing: -.11px;
		}
	}

	&--std {
		h2 {
		    margin-bottom: 1.5rem;
			font-size: 3.2rem;
			line-height: 4rem;
			font-weight: 400;
			letter-spacing: 0;
		}

		.sm-common-title__desc {
			margin-bottom: 1rem;
			font-size: 1.8rem;
			line-height: 2.6rem;
			letter-spacing: -.11px;
		}

		@media(max-width: $screen-md) {
			h2 {
				font-size: 2.4rem;
				line-height: 2.6rem;
			}
		}
		@media (max-width: $screen-sm) {
			h2 {
				font-size: 2.4rem;
				line-height: 2.8rem;
			}

			.sm-common-title__desc {
				font-size: 1.6rem;
			}
		}
	}
	
	&--large {
		h2 {
			margin-bottom: 1.5rem;
			font-size: 4rem;
			line-height: 5rem;
			font-weight: 400;
			letter-spacing: -.32px;
			font-family: $font-solomonLight;
		}
		
		.sm-common-title__desc {
			margin-bottom: 1rem;
			font-size: 1.6rem;
			line-height: 2.6rem;
			letter-spacing: -.11px;
		}
		
		@media(max-width: $screen-md) {
			h2 {
				font-size: 3rem;
				line-height: 3.5rem;
			}		
		}
		@media (max-width: $screen-sm) {
			h2 {
				font-size: 2.4rem;
				line-height: 3rem;
			}

			.sm-common-title__desc {
				font-size: 1.6rem;
			}
		}
	}

	&--bold h2 {
		font-family: $font-solomon;
	}

	&--narrow {
		max-width: 68rem;
	}
}


// General Slick Slider JS control styles
.slick-slider {
	display: none;
}
.slick-slider.slick-initialized {
	display: block;
}

.slick-dots {
	display: flex;
	justify-content: center;

	li {
		cursor: pointer;
		&.slick-active {
			button { background: $grey-black; }
		}

		button {						// Group selection dots
			width: 0.9rem;
			margin: 0 0.3rem;
			height: 0.9rem;
			border-radius: 50%;
			font-size: 0;
			background: rgba(72,97,117,0.3);
			border: none;
			cursor: pointer;
		}
	}
}

.slick-slider.white-dots .slick-dots li button {
	background: rgba(255,255,255,.3);
}
.slick-slider.white-dots .slick-dots li.slick-active button {
	background: $white;
}


.slick-arrow {									// Group navigation arrows
	position: absolute;
	width: 5rem;
	height: 5rem;
	top: calc(50% - 3.5rem);
	border: none;
	z-index: 1;
	outline-offset: 0.5rem;
	cursor: pointer;
	
	&.slick-prev {
		left: 0;
		background: url('images/arrow_left.svg') no-repeat center/contain;
	}
	&.slick-next {
		right: 0;
		background: url('images/arrow_right.svg') no-repeat center/contain;
	}

	@media(max-width: $screen-md) {
		width: 3.5rem;
		height: 3.5rem;
	}
}

.slick-list {
	margin-left: -3rem;
	margin-right: -3rem;
//	pointer-events: none;
}

.slick-slide {
	margin-left:  3rem;
	margin-right:  3rem;
}

.slick-slide > div + div {
	margin-top: 3rem;
}

.slick-arrows .slick-list {
	margin-left: 4rem;
	margin-right: 4rem;
	
/*	@media (max-width: $screen-sm) {
		margin-left: 0;
		margin-right: 0;
	}*/
}



