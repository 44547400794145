.cookie-modal {
	&__widget {
		max-width: 90rem;
		margin: 2rem;
		background: $white;
	}
	
	&__loader {
		margin: 10rem;
		text-align: center;
	}
}

.cookie-popup-page {
	padding: 1rem;

	.title {
		margin: 1rem 0;
		font-weight: 700;
	}
	
	.btn-group {
		margin: 0;
		text-align: center;
	}
	
	.control {
		display: flex;
		margin: 2rem 0;
		
		@media(max-width: $screen-sm) {
			margin: 1rem 0;
			font-size: 1.4rem;
			line-height: 1.2;
		}

		.siteBtn {
			min-width: 9rem;
			margin: 0;
		}
	}
	
	.control-text {
		margin-left: 1rem;
	}
	
	label {
		display: block;
		font-weight: 700;
	}
}
