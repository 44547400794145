.single-blog {
	&__banner {
		img {
			width: 100%;
			max-height: 45rem;
			object-fit: cover;
			object-position: top;

			@media(max-width: $screen-sm) {
				display: none;
			}
		}
	}

	&__main {
		padding: 4rem;

		@media(max-width: $screen-md) {
			padding: 4rem 0;
		}
		@media(max-width: $screen-sm) {
			padding: 0;
		}
	}
	
	&__article {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		@media(max-width: $screen-md) {
			display: block;
		}
	}

	&__left {
		flex: 0 0 75%;
		max-width: 75%;
		padding-right: 4.5rem;
		
		@media(max-width: $screen-md) {
			flex: none;
			max-width: 100%;
			padding-right: 0;
		}
		
		h1 {
			margin-bottom: 1.5rem;
			font-size: 4rem;
			font-weight: 400;	
			line-height: 5rem;
			letter-spacing: -.32px;
			color: $grey-black;
			
			@media(max-width: $screen-md) {
				font-size: 3rem;
				line-height: 3.5rem;
			}
			@media(max-width: $screen-sm) {
				font-size: 2.4rem;
				line-height: 3rem;
			}
		}
	}
	
	&__info {
		display: flex;
		padding: 1rem 0;
		align-items: center;
		border: solid $green;
		border-width: 0.1rem 0;

		li {
			font-size: 1.6rem;
			padding: 0 2.5rem;
			color: $green;
			@media(max-width: $screen-md) {
				font-size: 1.4rem;
			}
			@media(max-width: $screen-sm) {
				padding: 0 1rem;
			}
			
			&:first-child {
				padding-left: 0;
			}
			
			&:not(:last-child) {
				border-right: 0.1rem solid $green;
			}
			
			a {
				display: inline-block;
				margin-right: 0.5rem;
				&:not(:last-child)::after {
					content:', ';
				}
			}
		}
	}
	
	
	&__content {
		padding: 3rem 0 0;
		
		p {
			font-size: 1.8rem;
			line-height: 2.4rem;
			margin-bottom: 2rem;
			color: $grey-black;

			@media(max-width: $screen-md) {
				font-size: 1.6rem;
				line-height: 2.2rem;
			}
		}
		
		ul, ol {
			margin: 1.5rem 0;
		}

		li {
			font-size: 1.8rem;
			line-height: 2.4rem;
			padding-bottom: 0.4rem;
			
			@media(max-width: $screen-md) {
				font-size: 1.6rem;
				line-height: 2.2rem;
			}
		}
		
		ul li {
			list-style: circle inside;
		}
		
		ol li {
			list-style: decimal inside;
		}
		
		em {
			font-style: italic;
		}

		a, b, em {
			color: $green;
		}

		strong, b {
			font-weight: 600;
		}
		
		h2 {
			margin-bottom: 1.5rem;
			font-size: 4rem;
			line-height: 5rem;
			letter-spacing: -.32px;
			color: $grey-black;
		}
		
		h3 {
			margin-bottom: 0.5rem;
			font-size: 3.2rem;
			font-weight: 400;	
			line-height: 4rem;
			letter-spacing: 0;
			color: $grey-black;
		}
		
		h4 {
			font-size: 2.4rem;
			font-weight: 400;
			line-height: 3.2rem;
			letter-spacing: 0;
			color: $off-black;
		}
		
		img {
			display: block;
			margin: 3rem 0;

			@media(max-width: $screen-sm) {
				width: 50%;
				height: auto;
			}
		}
	}


	&__author{
		flex: 0 0 25%;
		max-width: 25%;		
		background: $off-black;
		
		@media(max-width: $screen-md) {
			flex: none;
			max-width: 100%;
			margin-top: 4rem;
		}

		&__inner {
			padding: 2rem;
		}

		h3 {
			padding-bottom: 0.3rem;
			font-size: 1.2rem;
			line-height: 2.6rem;
			letter-spacing: 1.78px;
			border-bottom:.05rem solid rgba(249, 249, 249, 0.5);
			color: $green;
		}
		
		&__header {
		    display: flex;
			padding-top: 1rem;
		}
		
		&__left {
			width: calc(100% - 8rem);
		}
		
		&__name {
			margin-bottom: .3rem;
			font-size: 1.6rem;
			line-height: 2.6rem;
			letter-spacing: -.19px;
			color: #f9f9f9;
		}

		&__position {
			font-size: 1.2rem;
			color: #05cfab;
		}

		&__company {
			font-size: 1.2rem;		
			color: #fff;
		}
		
		&__right {
			img {
				width: 6.5rem;
				height: 6.5rem;
			}
		}
		
		&__bio {
			margin: 1.5rem 0;
			font-size: 1.4rem;
			line-height: 2.2rem;
			letter-spacing: -.11px;
			color: $white;
		}

		&__footer {
			a {
				display: block;
				padding-top: 0.8rem;
				margin-right: 2rem;
				font-size: 2rem;
				opacity: 0.5;
			}
			
			a:hover, a:focus {
				opacity: 1;
			}
			
			svg {
				width: 2.5rem;
				fill: #fff;
			}
		}
	}
}