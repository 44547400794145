//	All global styles that should be delivered to the client but not to the Gutenberg editor
@mixin sm-client-globals {

html, body {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;

	body {
		// Force footer to bottom on short content
		display: flex;
		box-sizing: border-box;
		min-height: 100vh;
		flex-direction: column;

		width: 100%;
		overflow-x: hidden;
		font-stretch: normal;
		font-style: normal;
		font-size: 1.6rem;
		font-family: $font-solomon;
		line-height: 1.5;
		letter-spacing: normal;
		color: $grey;
	}
}
}

//	All global styles that should be delivered to the Gutenberg editor but not to the client
@mixin sm-editor-globals {

font-size: 12px;
overflow-x: hidden;
font-stretch: normal;
font-style: normal;
font-family: $font-solomon;
line-height: 1.5;
letter-spacing: normal;
color: $grey;
}


//	All global styles that should be delivered globally to the client and locally to the Gutenberg editor
@mixin sm-all-globals {

*, ::after, ::before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


img {
	max-width: 100%;
}


p {
	font-size: 1.4rem;
	line-height: 2.2rem;
	letter-spacing: -0.11px;
	opacity: 1;
	color: $grey;

	&:last-child {
		margin: 0;
	}

	&:nth-last-of-type {
		margin: 0;
	}
}


a {
	text-decoration: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: inherit;

	&:hover, &:focus {
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		text-decoration: underline;
		color: inherit;
	}

	img {
		border: none;
		max-width: 100%;
		object-fit: cover;
	}
}

ul {
	margin: 0;
	list-style-type: none;

	li {
		margin: 0;
		padding: 0;
	}
}

section {
	position: relative;
} 

article, aside, canvas, figure, figure img, figcaption, hgroup, section, audio, video {
	display: block;
}

table {
	border-collapse: separate;
	border-spacing: 0;

	caption, th, td {
		text-align: left;
		vertical-align: middle;
	}
}

input, select, textarea {
	width: 100%;
	padding: 1rem 1.5rem;
	font-family: $font-solomon;
	font-size: 1.8rem;
	line-height: 2.6rem;
	border: solid 1px $lightgrey-2;
	background-color: $white;
	color: $off-black;

	&:focus, &:active {
		outline: 1px auto $black;
	}
}

input, select {
	height: 4.8rem;
}
input[type=submit] {
	cursor: pointer;
}

::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
	opacity: 1;
	color: inherit;
}


// <em> is used to provide theme colouring across the site
em {
	font-style: normal;
}


.clearfix {
	clear: both;
}

// Prevent outline borders when the user is not using a keyboard
body:not(.user-is-tabbing) button:focus {
	outline: none;
}


}