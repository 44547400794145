.sm-tiled-blocks {
	@include sm-block-globals;

	padding: 4.7rem 0;
	position: relative;
	
	p {
		letter-spacing: inherit;
		font-size: inherit;
		line-height: inherit;
		color: inherit;
	}

	// Image floating on the right
	&__right-image {
		position: absolute;
		right: 3.2rem;
		top: 3.2rem;

		@media(max-width: $screen-sm) {
			display: block;
			position: static;
			margin-bottom: 2rem;
		}

		img {
			display: block;
			width: 10.3rem;
			height: auto;
			margin: 0 auto;
			@media(max-width: $screen-md) {
				width: 6.8rem;
			}
			@media(max-width: $screen-sm) {
				width: 10.3rem;
			}
		}
	}

	&__blocks {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -2rem;
	}
	
	&__block {
		max-width: 44rem;
		min-width: 30rem;
		margin: 2rem auto;
		padding: 0 1rem;

		&__inner {
			display: flex;
			justify-content: space-between;
			flex-direction: column;			
			padding: 3rem 1.5rem;
			margin: 0 1rem;
			text-align: center;
			height: 100%;
			position: relative;

			&--border {
				border-radius: 0.6rem;
				box-shadow: 0 2px 11px 0 rgba(72,97,117,0.3);
			}

			&--no-border {
				padding-left: 0rem;
				padding-right: 0rem;
			}
		}

		&__img {
			margin-bottom: 1rem;

			a {
				display: block;
			}

			img {
				display: block;
				width: auto;
				height: 7rem;
				margin: 0 auto;
			}
		}

		h3 {
			margin-bottom: 2rem;
			font-size: 3.2rem;
			font-weight: 400;
			color: inherit;
			
			&::after {
				@include sm-common-underline;
				margin: 1.5rem auto 0;
			}			
		}
		
		&__desc {
			max-width: 34rem;
			margin: 0 auto 1rem;
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
		
		&__features {
			margin: 0 0 1rem;
			font-size: 1.6rem;
			line-height: 1.5;
			letter-spacing: -.11px;
			li + li {
				margin-top: 2rem;
			}
		}

		&--small-text { 
			h3 {
				font-size: 2.4rem;
				line-height: 3.2rem;
			}
			
			.sm-tiled-blocks__block__desc {
				font-size: 1.4rem;
				line-height: 2.2rem;
			}
			
			.sm-tiled-blocks__block__features {
				font-size: 1.4rem;
				line-height: 2.2rem;
			}
		}

		&--tall {
			.sm-tiled-blocks__block__inner {
				padding-top: 6rem;
				padding-bottom: 6rem;
			}
		}

		&__btns {
			a {
				min-width: 22rem;
				margin: 3rem 1rem 0;
				padding: 0;
			}
			
			a + a {
				margin: 1rem 1rem 0;
			}
		}
	}

	// Image height overrides
	&--image-6 .sm-tiled-blocks__block__img img { height: 6rem; }
	&--image-7 .sm-tiled-blocks__block__img img { height: 7rem; }
	&--image-8 .sm-tiled-blocks__block__img img { height: 8rem; }
	&--image-9 .sm-tiled-blocks__block__img img { height: 9rem; }
	&--image-10 .sm-tiled-blocks__block__img img { height: 10rem; }
}
