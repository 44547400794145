@import "components/general";
@import "components/buttons";
@import "components/header";
@import "components/footer";
@import "components/search-form";
@import "components/enquiry";
@import "components/video-player.scss";

@import "pages/single-blog";
@import "pages/default";
@import "pages/cookie";
@import "pages/404";
@import "pages/index";

@import "blocks/standard-banner.scss";
@import "blocks/people.scss";
@import "blocks/divider.scss";
@import "blocks/enquiry-form.scss";
@import "blocks/contact-banner.scss";
@import "blocks/office-maps.scss";
@import "blocks/tiled-blocks.scss";
@import "blocks/icon-slider.scss";
@import "blocks/review-slider.scss";
@import "blocks/blog-slider.scss";
@import "blocks/text-with-side-image.scss";
@import "blocks/alternating-partners.scss";
@import "blocks/text-with-image-banner.scss";
@import "blocks/image-with-features.scss";
@import "blocks/post-scroller.scss";
@import "blocks/sign-up.scss";
@import "blocks/testimonial.scss";


// Branding specific colouring. See also siteBtn and scroll-icon2
// Ensure this section is last so the colours override earlier statements
.sm-bg--theme-1,			.sm-bgafter--theme-1::after 			{ background: linear-gradient(210deg, #FB7268 0%, #E82B7A 100%); }
.sm-bg--theme-2,			.sm-bgafter--theme-2::after 			{ background: linear-gradient(270deg, #18E1E9 0%, #00A7EE 100%); }
.sm-bg--theme-3,			.sm-bgafter--theme-3::after 			{ background: linear-gradient(270deg, #18E1E9 0%, #05CFAB 100%); }
.sm-bg--theme-4,			.sm-bgafter--theme-4::after 			{ background: linear-gradient(270deg, #fe92fe 0%, #800080 100%); }
.sm-bg--pink,				.sm-bgafter--pink::after 			{ background: $pink; } 																	// Solid version of theme-1
.sm-bg--blue,				.sm-bgafter--blue::after 			{ background: $blue; } 																	// Solid version of theme-2
.sm-bg--green,			.sm-bgafter--green::after 			{ background: $green; } 																	// Solid version of theme-3
.sm-bg--purple,			.sm-bgafter--purple::after 			{ background: $purple; } 																	// Solid version of theme-4
.sm-bg--white, 			.sm-bgafter--white::after			{ background: $white; } 
.sm-bg--greyblack,		.sm-bgafter--greyblack::after 		{ background: $grey-black; } 
.sm-bg--offblack,			.sm-bgafter--offblack::after 		{ background: $off-black; } 
.sm-bg--offwhite,			.sm-bgafter--offwhite::after 		{ background: $off-white; }
.sm-bg--offwhite1,		.sm-bgafter--offwhite1::after 		{ background: $off-white1; }
.sm-bg--blacktri,			.sm-bgafter--blacktri::after 		{ background: #373e4b url('images/triangle-black.jpg') no-repeat 0%/cover; }		// cover
.sm-bg--smallblacktri,	.sm-bgafter--smallblacktri::after	{ background: #373e4b url('images/triangle-black.jpg') repeat 0%/auto 50%; }		// small
.sm-bg--offwhitetri,		.sm-bgafter--offwhitetri::after		{ background: #eeeeee url('images/light-curves-grey.jpg') no-repeat 0%/cover; }	// Partners
.sm-bg--bigblacktri,		.sm-bgafter--bigblacktri::after		{ background: #373e4b url('images/keyfeature-bg-black.jpg') no-repeat 0%/cover; }	// Tiled Blocks
.sm-bg--lgreytri,			.sm-bgafter--lgreytri::after 		{ background: $grey-black url('images/case-top-bg.png') no-repeat 0%/cover; }			// Single Case

// Text and emphasis colours to match button colours
.sm-col--pink,			.sm-em--pink em,		.sm-em--pink a				{ color: $pink; }
.sm-col--blue,			.sm-em--blue em,		.sm-em--blue a				{ color: $blue; }
.sm-col--green,			.sm-em--green em,		.sm-em--green a			{ color: $green; }
.sm-col--purple,		.sm-em--purple em,		.sm-em--purple a			{ color: $purple; }
.sm-col--white,			.sm-em--white em,		.sm-em--white a			{ color: $white; }
.sm-col--greyblack,		.sm-em--greyblack em,	.sm-em--greyblack a		{ color: $grey-black; }
.sm-col--offblack,		.sm-em--offblack em,	.sm-em--offblack a		{ color: $off-black; }
.sm-col--offwhite,		.sm-em--offwhite em,	.sm-em--offwhite a  		{ color: $off-white; }
.sm-col--offwhite1,		.sm-em--offwhite1 em,	.sm-em--offwhite1 a 		{ color: $off-white1; }

// Border and bullet-point colours
.sm-border--pink,			.sm-afterb--pink::after			{ border-color: $pink; }
.sm-border--blue,			.sm-afterb--blue::after			{ border-color: $blue; }
.sm-border--green,		.sm-afterb--green::after			{ border-color: $green; }
.sm-border--purple,		.sm-afterb--purple::after			{ border-color: $purple; }
.sm-border--white, 		.sm-afterb--white::after 		{ border-color: $white; } 
.sm-border--greyblack,	.sm-afterb--greyblack::after		{ border-color: $grey-black; } 
.sm-border--offblack,		.sm-afterb--offblack::after		{ border-color: $off-black; } 
.sm-border--offwhite,		.sm-afterb--offwhite::after		{ border-color: $off-white; }
.sm-border--offwhite1	,	.sm-afterb--offwhite1::after		{ border-color: $off-white1; }

// <u> element underline colours
.sm-underline u {
	display: block;
	text-decoration: none;

	&::after {
		@include sm-common-underline;
		margin: .5rem 0 .5rem;
	}
}
.sm-underline--centre u {
	&::after {
		margin: .5rem auto .5rem;
	}
}
.sm-underline--pink			u::after	{ background: $pink; }
.sm-underline--blue			u::after	{ background: $blue; }
.sm-underline--green			u::after	{ background: $green; }
.sm-underline--purple			u::after	{ background: $purple; }
.sm-underline--white 			u::after	{ background: $white; } 
.sm-underline--greyblack		u::after	{ background: $grey-black; } 
.sm-underline--offblack		u::after	{ background: $off-black; } 
.sm-underline--offwhite		u::after	{ background: $off-white; }
.sm-underline--offwhite1		u::after	{ background: $off-white1; }

// Input outline colours
.sm-outline--pink 		{ input:focus, textarea:focus { outline-color: $pink; } 			select:focus { outline-color: $pink; 		border: 1px solid $pink; } }
.sm-outline--blue 		{ input:focus, textarea:focus { outline-color: $blue; } 			select:focus { outline-color: $blue; 		border: 1px solid $blue; } }
.sm-outline--green 		{ input:focus, textarea:focus { outline-color: $green; } 			select:focus { outline-color: $green; 		border: 1px solid $green; } }
.sm-outline--purple		{ input:focus, textarea:focus { outline-color: $purple; } 			select:focus { outline-color: $purple; 		border: 1px solid $purple; } }
.sm-outline--white 		{ input:focus, textarea:focus { outline-color: $white; } 			select:focus { outline-color: $white; 		border: 1px solid $white; } }
.sm-outline--greyblack 	{ input:focus, textarea:focus { outline-color: $grey-black; } 	select:focus { outline-color: $grey-black; border: 1px solid $grey-black; } }
.sm-outline--offblack 	{ input:focus, textarea:focus { outline-color: $off-black; } 	select:focus { outline-color: $off-black; 	border: 1px solid $off-black; } }
.sm-outline--offwhite 	{ input:focus, textarea:focus { outline-color: $off-white; } 	select:focus { outline-color: $off-white; 	border: 1px solid $off-white; } }
.sm-outline--offwhite1 	{ input:focus, textarea:focus { outline-color: $off-white1; } 	select:focus { outline-color: $off-white1; border: 1px solid $off-white1; } }

