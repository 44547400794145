.sm-alternating-partners {
	@include sm-block-globals;

	&__row {

		&:nth-child(even) {
			.sm-row {
				flex-direction: row-reverse;
			}
		}
	}

	.sm-row {
		& > div {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 4.7rem;
			margin: 0 auto;
			
			@media(max-width: $screen-sm) {
				flex: none;
				max-width: 100%;
			}			
		}
	}

	&__text {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: -1;
		}

		h2 {
			margin-bottom: 1.5rem;
			font-family: $font-solomonLight;
			font-weight: 400;
			font-size: 4rem;
			line-height: 5rem;
			letter-spacing: -.32px;

			@media(max-width: $screen-md) {
				font-size: 3rem;
				line-height: 3.5rem;			
			}
	
			u {
				display: block;
				margin-bottom: 3rem;
				font-family: $font-solomon;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 2.6rem;
				letter-spacing: 1.78px;
				vertical-align: top;
				text-transform: uppercase;
				text-decoration: none;
				
				&::after {
					@include sm-common-underline;
					margin: 1.5rem 0 0;
				}
			}
		}
	
		.btn-group {
			margin-top: 3.5rem;		
		}
	}

	&__desc {
		font-size: 1.8rem;
		line-height: 2.4rem;
		letter-spacing: -.11px;	
	}

	&__partner {
		display: flex;
		align-items: center;
		color: $grey-black;

		h2 {
			line-height: 4rem;		
			font-size: 2.4rem;
			font-weight: 400;
			letter-spacing: -.19px;
		}
		
		img {
			height: auto;
			max-width: 26rem;
			margin: 0 auto 3rem;
		}
	}
	
	&__pinner {
	    max-width: 34.5rem;
		margin: 0 auto;
		text-align: center;
	}

	&__pdesc {
	    margin-bottom: 1rem;
		line-height: 2rem;
		font-family: Solomon-Sans-SemiBold;	
		font-size: 1.4rem;
		letter-spacing: -.11px;
	}
	
	&__link {
		color: $blue;
	}
}