/* Popup search modal form */
.search-modal {

	&__widget {
		width:		90%;
		max-width:	80rem;
		position:	relative;
		margin:	-30rem auto 0;
		
		/* Shaded bottom border */
		&::after {
			content:'';
			display:block;
			height:0.5rem;
			background: linear-gradient(180deg, #18E1E9 0%, #05CFAB 100%);
		}
	}

	// On phones, move the search bar to the top to prevent page overflow
	@media(max-width: $screen-sm) {
		&.custom-modal {
			align-items: start;
		}
		&__widget {
			width: 96%;
			margin: 10rem auto 0;
		}
	}

	input {
		min-height:	11rem;
		line-height:	11rem;
		padding:		2rem 10rem 2rem 4rem;
		border-radius:0.4rem 0.4rem 0 0rem;
		border:		1px solid #6d717b;
		font-family:	$font-solomonLight;
		font-size:		3rem;
		letter-spacing: -0.24px;
		color:			$white;
		background:	$off-black;

		@media(max-width: $screen-md) {
			min-height:	7rem;
			line-height:	7rem;
			font-size:		2.2rem;
			padding:		1rem 6rem 1rem 1.5rem;
		}
	}
		
	/* Submit button displayed as a fat search icon */
	button[type="submit"] {
		position:	absolute;
		border:	none;
		right:		2rem;
		top:		2.4rem;
		background: none;
		cursor: pointer;
	
		svg {
			width:			6.4rem;
			padding:		0.5rem;
			stroke-width:	40px;
			stroke:		#05cfa9;
			fill:			none;
		}

		@media(max-width: $screen-md) {
			right:	1rem;
			top:	1rem;

			svg {
				width:			5rem;
				padding:		0.5rem;
				stroke-width:	30px;			
			}
		}
	}

	/* Vertical list of search results */
	&__results {
		position:		absolute;
		top:			100%;
		left:			0;
		right:			0;
		max-height:	34rem;
		overflow-y:	auto;
		background:	$white;

		li {
			display:		block;
			padding:		1.2rem 4rem;
			border-bottom:0.5px solid rgba(151, 151, 151, 0.9);
			color:			#080c0e;
			font-family:	$font-solomonLight;
			font-size:		2.6rem;

			@media(max-width: $screen-md) {
				padding:	1.2rem 2rem;
				font-size:	2rem;
			}
		}
	}
}

/* Standard search form used on the index page */
.search-form {
    margin-bottom: 2rem;

	input[type="search"] {
		width: 85%;
	}

	button[type="submit"] {
		vertical-align: middle;
		border:	none;
		background: none;
		cursor: pointer;
	
		svg {
			width:			5rem;
			height:		5rem;
			padding:		0.5rem;
			stroke-width:	40px;
			stroke:		$pink;
			fill:			none;
		}

		@media(max-width: $screen-md) {
			right:	1rem;
			top:	1rem;

			svg {
				width:			5rem;
				padding:		0.5rem;
				stroke-width:	30px;			
			}
		}
	}

}